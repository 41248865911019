<template>
    <div id="login">
        
        <div class="main-img">
            <img src="images/bft-logo.svg" alt="Avatar" class="avatar">
        </div>
        <div class="box1"> 
            <form @submit.prevent="handleSubmit">
                <div class="imgcontainer">
                
                </div>
                <div class="container">
                    <h4 class="main-text">Velkom til BFT! </h4>
                    <h5 class="main-text-2">Venligst login</h5>
                    <label for="uname"><b class="e-text">Brugernavn</b></label>
                    <input
                        type="text" 
                        :placeholder="$t('Enter Username')"
                        v-model="login"
                        :class="{
                            'is-invalid': submitted && $v.login.$error,
                        }"
                    />
                    <div
                        v-if="submitted && !$v.login.required"
                        class="invalid-feedback"
                    >
                        {{ $t('username is required') }}
                    </div>

                    <input 
                        type="password" 
                        :placeholder="$t('Enter Password')"
                        v-model="pass"
                        :class="{
                            'is-invalid': submitted && $v.pass.$error,
                        }"
                    />
                    <div
                        v-if="submitted && !$v.login.required"
                        class="invalid-feedback"
                    >
                        {{ $t('password is required') }}
                    </div>
                        
                    <button type="submit">{{ $t('Login') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "Login",
        components: {
             
        },
        data() {
            return {    
                login : "",
                pass : "",
                submitted : false   
            };
        },

        validations: {
            login: { required },
            pass: { required }
        },
        created(){
            this.mytoken = localStorage.getItem("token");
            if(this.mytoken == null || this.mytoken == ""){
                this.loggedIn = false;
                this.windowurl();
            }else{
                this.loggedIn = true;
                this.$router.push({ name: 'Overview'});
            }
        },
        methods: {
            windowurl(){
                history.pushState(null, null, location.href);
                window.onpopstate = function() {
                    history.go(1);
                }
            },
            handleSubmit() { 
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                else {
                    var logindata = JSON.stringify({
                        login: this.login,
                        pass: this.pass,
                    });
                    var config = {
                        method: "post",
                        url: "https://engine.bftlogistik.dk:7000/login/v1/retrievetoken",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: logindata,
                    };
                    //console.log(logindata);
                    axios(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.token));
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("username", this.login);
                        localStorage.setItem("loggedIn", true);
                        this.$router.push({ name : 'Overview' });
                        location.reload();
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert("User Not Found");
                    });
                }
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap");
    * {
        box-sizing: border-box;
    }
    html,
    body {
        margin: 0;
        padding: 0;
    }
    html {
        background-color: #225268;
    }
    body {
        color: #ffffff;
    }
    #app {
        font-family: "Raleway", sans-serif;
    }
    form {
        width: 70%;
        margin: 10em auto;
        
    }

    input[type=text], input[type=password] {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #94956c;
    }

    button {
            color: white;
            padding: 14px 20px;
            margin: 8px 0;
            border: none;
            cursor: pointer;
            width: 100%;
            border-radius: 10px;
            font-size: 17px;
            font-weight: 600;
            background-color: #7367f0b3;
    }

    button:hover {
        opacity: 0.8;
    }

    .cancelbtn {
        width: auto;
        padding: 10px 18px;
        background-color: #f44336;
    }

    .imgcontainer {
        text-align: center;
        margin: 24px 0 12px 0;
    }

    img.avatar {
        width: 40%;
        margin: 10em auto;
    }
    .main-img{
        float: left;
        width: 60%;
        text-align: center;
        margin-top: 12%;
    }

    .container {
        padding: 16px;
    }

    span.psw {
        float: right;
        padding-top: 16px;
    }
    .main-text{
        color:#d0d2d6;
        font-size: 24px;
    }
    .main-text-2{
        color:#b2b2b5;
        font-size: 12px;   
    }
    .box1{
        width: 40%;
        height: 774px;
        float: right;
        background-color: rgb(1, 73, 99);
    }
    .e-text{
        color:#b2b2b5;
        font-size: 12px;
    }
    @media screen and (max-width: 300px) {
        span.psw {
            display: block;
            float: none;
        }
        .cancelbtn {
            width: 100%;
        }
    }

</style>
